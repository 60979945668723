/* ==================================================
Toggle element:

Toggles animated display of element with an id that
matches the 'data-toggle-trigger' of the clicked
element. The default animation is slide in and
slide out.
===================================================*/

$('a[data-toggle-trigger]').click(function (e) {
  e.preventDefault();
  const triggerId = $(this).data('toggle-trigger');
  const toggleItem = $(`#${triggerId}`);

  $(this).toggleClass('open');

  const $toggleElement = new Foundation.Toggler(toggleItem, {
    animate: 'animate-in animate-out',
  });

  toggleItem.foundation('toggle');
});


/* ==================================================
Mobile nav toggle:

Toggles the mobile nav with Foundation toggler and
animations.
===================================================*/
(function() {
  $('.mobile-menu-toggle .nav-label').click(function (e) {
    e.preventDefault();
    var $toggleItem = $('.main-nav-small');
    $(this).toggleClass('open');
    $(this).children('.hamburger').toggleClass('active');

    const $toggleElement = new Foundation.Toggler($toggleItem, {
      animate: 'nav-slide-in nav-slide-out',
    });
    $toggleItem.toggleClass('open');
    $toggleItem.foundation('toggle');
  });
})();


/* ==================================================
Smooth Scrolling :

Smooth Scrolling Jquery Plugin
===================================================*/

// Select all links with hashes
$('a[href^="#"]').on('click',function (e) {
      e.preventDefault();

      var target = this.hash;
      var $target = $(target);

      $('html, body').stop().animate({
          'scrollTop': $target.offset().top
      }, 900, 'swing', function () {
          window.location.hash = target;
      });
  });

//Stop Anchor Being Included In URL
// $('html, body').stop().animate({
//      'scrollTop': $target.offset().top
// }, 900, 'swing');

//Utilities
$(function(){
  $('html').removeClass('no-js');
});


/* ==================================================
AJAX Form Submission

Submits the form to BC via AJAX and on successful
response hides the form and animates in a thanks
message
===================================================*/
Foundation.Abide.defaults.validators.requiredCaptcha = function ($el) {
    if (grecaptcha.getResponse().length != 0) {
        return true;
    }
    return false;
};

var $formWrap = $('#contact-form-wrap');
var $message = $('#form-thanks');

$(document)
.on("formvalid.zf.abide", function(ev,frm) {
    var $form = frm;
    $.post($form.attr("action"), $form.serialize()).then(function() {
      $message.removeClass('hide').fadeIn();
      $formWrap.addClass('hide');
    });
});
$('#contact-form').on( "submit", function(event) {
    event.preventDefault();
});